import { render, staticRenderFns } from "./hd-switches.vue?vue&type=template&id=0453f8a8"
import script from "./hd-switches.vue?vue&type=script&lang=js"
export * from "./hd-switches.vue?vue&type=script&lang=js"
import style0 from "./hd-switches.vue?vue&type=style&index=0&id=0453f8a8&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CSwitch: require('/app/components/ui/c-switch.vue').default,VDivider: require('/app/node_modules/vuetify/lib/components/VDivider/VDivider.js').default,IconSearch: require('/app/components/icons/IconSearch.vue').default,VBtn: require('/app/node_modules/vuetify/lib/components/VBtn/VBtn.js').default,IconGrig: require('/app/components/icons/IconGrig.vue').default,IconList: require('/app/components/icons/IconList.vue').default,VBtnToggle: require('/app/node_modules/vuetify/lib/components/VBtnToggle/VBtnToggle.js').default,VIcon: require('/app/node_modules/vuetify/lib/components/VIcon/VIcon.js').default})


/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VBtn,VBtnToggle,VDivider,VIcon})
