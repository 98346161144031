import { render, staticRenderFns } from "./c-switch.vue?vue&type=template&id=4809cab4"
import script from "./c-switch.vue?vue&type=script&lang=js"
export * from "./c-switch.vue?vue&type=script&lang=js"
import style0 from "./c-switch.vue?vue&type=style&index=0&id=4809cab4&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Input: require('/app/components/ui/input/index.js').default})
