
import { mapGetters, mapActions } from "vuex";
import { getCroppedImageUrl } from "~/common/helpers";
import ProfileCardCompact from "@/components/v2/profile-card/profile-card-compact.vue";

export default {
  components: {
    ProfileCardCompact,
  },

  props: {
    type: {
      type: String,
      required: true,
      validator: (value) => ["compact", "wide"].includes(value),
    },
    profile: {
      type: Object,
      required: true,
    },
    bordered: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      ProfileCard: ProfileCardCompact,
      inFavorites: false,
      isFavoriteProcessing: false,
      loading: false,
    };
  },

  computed: {
    ...mapGetters({
      discreetMode: "getDiscreetStatus",
    }),

    profileImg() {
      return getCroppedImageUrl(
        this.profile.mainPhoto,
        "494x408", // TODO: as new cropp size
        this.discreetMode
      );
    },

    avatarAlt() {
      return `
				${this.profile?.name || ""},
				${this.age},
				${this.profile?.myDetails[0]?.hairColor || ""},
				${this.nationality || ""},
				${this.$t("escort-in-city", [this.city])} - ${this.profile?.id}
				`
        .replace(/\s+/g, " ")
        .trim();
    },

    cardTitle() {
      return `
				${this.profile?.name || ""},
				${this.age},
				${this.city} escort,
				${
          this.rates
            ? `${this.rates?.at(0)?.price || ""}-${
                this.rates?.at(-1)?.price || ""
              }AED`
            : ``
        }
			`
        .replace(/\s+/g, " ")
        .trim();
    },

    isTransgender() {
      return this.profile?.gender === "Transgender";
    },

    aboutMe() {
      return this.profile.myDetails[0]?.about_me || "";
    },

    city() {
      if (this.profile?.location && this.profile?.location?.length) {
        return this.profile.location[0].baseCity;
      }
      return "";
    },

    rates() {
      if (
        this.profile?.serviceDetails?.length &&
        this.profile?.serviceDetails[0].companionshipRates?.length
      ) {
        return this.profile.serviceDetails[0].companionshipRates;
      }
      return [];
    },

    age() {
      if (this.profile?.myDetails && this.profile?.myDetails?.length) {
        return this.profile.myDetails[0].age;
      }
      return "";
    },

    serviceFor() {
      if (
        this.profile?.serviceDetails?.length &&
        this.profile?.serviceDetails[0].serviceFor?.length
      ) {
        return this.profile.serviceDetails[0].serviceFor;
      }
      return [];
    },

    services() {
      if (
        this.profile?.serviceDetails?.length &&
        this.profile?.serviceDetails[0].service &&
        this.profile?.serviceDetails[0].service.length
      ) {
        return this.profile?.serviceDetails[0].service;
      }
      return [];
    },

    commonCardsData() {
      return {
        profileImg: this.profileImg,
        discreetMode: this.discreetMode,
        avatarAlt: this.avatarAlt,
        cardTitle: this.cardTitle,
        isTransgender: this.isTransgender,
        aboutMe: this.aboutMe,
        city: this.city,
        rates: this.rates,
        age: this.age,
        serviceFor: this.serviceFor,
        services: this.services,
      };
    },
  },

  watch: {
    type: "loadProfileCard",

    "profile.isFavorite"(val) {
      console.log("watch");

      this.inFavorites = val;
    },
  },

  created() {
    if (this.type !== "compact") {
      this.loadProfileCard();
    }
  },

  mounted() {
    this.inFavorites = this.profile.isFavorite;
  },

  methods: {
    ...mapActions({
      addToFafourites: "profiles/addToFafourites",
      removeFromFavourites: "profiles/removeFromFavourites",
    }),

    async loadProfileCard() {
      this.loading = true;
      try {
        this.ProfileCard = (
          await import(
            `@/components/v2/profile-card/profile-card-${this.type}.vue`
          )
        ).default;
      } catch (error) {
        console.error("Error loading profile card component:", error);
      } finally {
        this.loading = false;
      }
    },

    async processFavorite(profile) {
      if (this.profile?.id != profile.id) return;
      if (this.$auth.loggedIn) {
        this.isFavoriteProcessing = true;
        let response;
        if (this.profile.isFavorite) {
          response = await this.removeFromFavourites(this.profile.id);
        } else {
          response = await this.addToFafourites(this.profile.id);
        }

        console.log(response);

        if (response.data.status) {
          this.$store.commit("profiles/setFavorite", this.profile);
          this.inFavorites = !this.profile.isFavorite;
        }

        if (!response.data.status) {
          this.$notify({
            type: "warning",
            message: response.data.msg,
          });
        }
        this.isFavoriteProcessing = false;
      } else if (window.innerWidth < 1024) {
        this.$root.$emit("sign-in-drawer:open");
      } else {
        this.$router.push({ name: "auth-signin" });
      }
    },
  },
};
