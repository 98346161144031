import { render, staticRenderFns } from "./hd-applyed-filters.vue?vue&type=template&id=65089d6e"
import script from "./hd-applyed-filters.vue?vue&type=script&lang=js"
export * from "./hd-applyed-filters.vue?vue&type=script&lang=js"
import style0 from "./hd-applyed-filters.vue?vue&type=style&index=0&id=65089d6e&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VChip: require('/app/node_modules/vuetify/lib/components/VChip/VChip.js').default})


/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
installComponents(component, {VChip})
