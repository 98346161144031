
export default {
  props: {
    profile: {
      type: Object,
    },

    inFavorites: {
      type: Boolean,
      default: false,
    },

    isFavoriteProcessing: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isMenuOpen: false,
    };
  },

  computed: {
    linkToTwitter() {
      let link;

      if (this.profile) {
        link = `http://twitter.com/share?text=Some text about this model&url=${this.$config.baseUrlApp}/${this.profile.slug}`;
      }

      return link;
    },
  },

  methods: {
    openPmModal() {
      if (this.$auth.loggedIn) {
        this.isMenuOpen = false;
        this.$root.$emit("sendPM", this.profile);
      } else if (window.innerWidth < 1024) {
        this.$root.$emit("sign-in-drawer:open");
      } else {
        this.$router.push({ name: "auth-signin" });
      }
    },

    openReportModal() {
      if (this.$auth.loggedIn) {
        this.$root.$emit("openReportProfileModal", this.profile);
      } else if (window.innerWidth < 1024) {
        this.$root.$emit("sign-in-drawer:open");
      } else {
        this.$router.push({ name: "auth-signin" });
      }
    },

    addToFavorite() {
      if (this.$auth.loggedIn) {
        this.$emit("favoriteClicked", {
          ...this.profile,
          isFavorite: this.inFavorites,
        });
      } else if (window.innerWidth < 1024) {
        this.$root.$emit("sign-in-drawer:open");
      } else {
        this.$router.push({ name: "auth-signin" });
      }
    },
  },
};
