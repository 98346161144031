
import AppNav from "~/components/v2/common/header/desktop/hd-app-nav.vue";
import UserPanel from "~/components/v2/common/header/desktop/hd-user-panel.vue";
import CategoryNav from "~/components/v2/common/header/desktop/hd-category-nav.vue";
import Switches from "~/components/v2/common/header/desktop/hd-switches.vue";
import Selects from "~/components/v2/common/header/desktop/hd-selects.vue";
import ApplyedFilters from "~/components/v2/common/header/desktop/hd-applyed-filters.vue";

import { mapGetters } from "vuex";

const defaultInitDisplayOptions = {
  row1: true,
  row2: true,
  row3: true,
  row4: true,
  row5: true,
};

const defaultOnScrollDisplayOptions = {
  row1: true,
  row2: true,
  row3: false,
  row4: false,
  row5: true,
};

const defaultScrollOffsets = {
  row1: 50,
  row2: 50,
  row3: 50,
  row4: 50,
  row5: 50,
};

export default {
  name: "HeaderDesktop",
  components: {
    AppNav,
    UserPanel,
    CategoryNav,
    Switches,
    Selects,
    ApplyedFilters,

    AdvancedSearch: () =>
      import(
        "~/components/v2/common/header/modals/advanced-search/advanced-search.vue"
      ),
  },

  props: {
    initDisplayOptions: {
      type: Object,
      default: () => {},
    },

    onScrollDisplayOptions: {
      type: Object,
      default: () => {},
    },

    scrollOffsetsOptions: {
      type: Object,
      default: () => {},
    },

    forceRow4: {
      type: Boolean,
      default: false,
    },

    firstRowClasses: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      isFiltersPanelOpen: false,
      isAdvancedSearchOpen: false,

      scrolledPX: 0,
    };
  },

  computed: {
    ...mapGetters({
      currentCity: "global/getCurrentCity",
    }),

    initDO() {
      return {
        ...defaultInitDisplayOptions,
        ...this.initDisplayOptions,
      };
    },

    onScrollDO() {
      return {
        ...defaultOnScrollDisplayOptions,
        ...this.onScrollDisplayOptions,
      };
    },

    scrollOffsets() {
      return {
        ...defaultScrollOffsets,
        ...this.scrollOffsetsOptions,
      };
    },

    isOnScrollEnabled() {
      return Object.keys(this.onScrollDO).reduce((acc, key) => {
        return (
          acc ||
          (typeof this.onScrollDO[key] === "boolean" && this.onScrollDO[key])
        );
      }, false);
    },

    showRow1() {
      return (
        this.initDO.row1 ||
        (this.onScrollDO.row1 && this.scrolledPX >= this.scrollOffsets.row1)
      );
    },

    showRow2() {
      return (
        this.initDO.row2 ||
        (this.onScrollDO.row2 && this.scrolledPX >= this.scrollOffsets.row2)
      );
    },

    showRow3() {
      return (
        this.initDO.row3 ||
        (this.onScrollDO.row3 && this.scrolledPX >= this.scrollOffsets.row3)
      );
    },

    showRow4() {
      return (
        this.initDO.row4 ||
        (this.onScrollDO.row4 && this.scrolledPX >= this.scrollOffsets.row4)
      );
    },

    showRow5() {
      return (
        this.initDO.row5 ||
        (this.onScrollDO.row5 && this.scrolledPX >= this.scrollOffsets.row5)
      );
    },
  },

  beforeMount() {
    if (!this.isOnScrollEnabled) return;
    window.addEventListener("scroll", this.processBottomPartOnScroll, {
      passive: true,
    });
  },

  beforeDestroy() {
    if (typeof window !== "undefined" && this.isOnScrollEnabled) {
      window.removeEventListener("scroll", this.processBottomPartOnScroll);
    }
  },

  mounted() {
    this.$root.$on("openAdvancedSearch", this.openAdvancedSearch);
    this.$root.$on("closeAdvancedSearch", this.closeAdvancedSearch);

    const resizeObserver = new ResizeObserver(() => {
      this.$root.$emit("set-page-padding");
    });
    resizeObserver.observe(this.$el);
  },

  methods: {
    switchFiltersPanel(isOpen) {
      this.isFiltersPanelOpen = isOpen;
    },

    openAdvancedSearch() {
      this.isAdvancedSearchOpen = true;

      setTimeout(() => {
        this.$refs.AdvancedSearch?.open();
      }, 100);
    },

    closeAdvancedSearch() {
      this.$refs.AdvancedSearch.close();
    },

    openLocationModal() {
      this.$store.commit("global/setModalChangeCityStatus");
    },

    processBottomPartOnScroll() {
      this.scrolledPX = window.scrollY;
    },
  },
};
