
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      notifications: "notifications/getSidebarNotifications",
    }),

    favoriteNotifications() {
      return this.$auth.user?.favorites || 0;
    },

    messagesNotifications() {
      return this.notifications?.messages || 0;
    },

    bellNotifications() {
      let number = 0;

      for (let key in this.notifications) {
        if (key === "messages") continue;
        if (key === "pinboard") continue;
        if (key == "tariff") continue;
        else number += this.notifications[key];
      }

      return number;
    },

    favoriteUrl() {
      if (!this.$auth.loggedIn) {
        return "/auth/signin";
      }
      return this.$auth.user.user_role === 3
        ? "/dashboard/member/my-favourites"
        : this.$auth.user.user_role === 1
        ? "/dashboard/manager/favourites"
        : "/dashboard/my-favourites";
    },

    messagesUrl() {
      if (!this.$auth.loggedIn) {
        return "/auth/signin";
      }
      return this.$auth.user.user_role === 3
        ? "/dashboard/member/private-messages"
        : this.$auth.user.user_role === 1
        ? "/dashboard/manager/messages"
        : "/dashboard/private-messages";
    },
  },
  methods: {
    logOut() {
      this.$auth.logout();
    },
  },
};
