
export default {
  props: {
    value: {
      type: [Boolean, String, Number],
      default: false,
    },
    label: {
      type: String,
      default: "Switch",
    },
    labelPosition: {
      type: String,
      default: "left", // left, right
    },
    trueValue: {
      type: [Boolean, String, Number],
      default: true,
    },
    falseValue: {
      type: [Boolean, String, Number],
      default: false,
    },
    size: {
      type: String,
      default: "m", // s, m, l
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    checkedColor: {
      type: String,
      default: "#ff0000",
    },
    labelColor: {
      type: String,
      default: "#fff",
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
  },

  emits: ["change"],

  methods: {
    onChange(e) {
      const value = e.target.checked ? this.trueValue : this.falseValue;
      this.$emit("input", value);
      this.$emit("change", value);
    },
    clickLabel() {
      this.$el.querySelector("input").click();
    },
  },
};
