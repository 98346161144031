
import { mdiCheck } from "@mdi/js";
import { mapGetters } from "vuex";
import {
  objectToArrayOfObjects,
  setSelectedOptions,
  debounce,
} from "~/common/helpers";

function keysCount(obj = {}, ignore = []) {
  return Object.keys(obj).filter((key) => !ignore.includes(key)).length;
}

const defaultForm = {
  service: [],
  nationality: [],
  age: [],
  hair_color: [],
  budget: [],
  district: [],
};

export default {
  props: {
    showApplyBtn: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      mdiCheck,

      form: {
        ...defaultForm,
      },
    };
  },

  computed: {
    ...mapGetters({
      filters: "profiles/getFilters",
      filtersList: "profiles/getFiltersList",
      currentCity: "global/getCurrentCity",
      availableFilters: "profiles/get_available_filter_value",
    }),

    isFiltersApplied() {
      return (
        keysCount(this.filters, [
          "per_page",
          "page",
          "OrderDirection",
          "city",
          "gender",
          "area",
          "new",
          "awaitingCall",
          "qualityMark",
          "verified",
          "incall",
          "outcall",
          "inTour",
        ]) >= 1
      );
    },

    servicesSelectOptions() {
      const service = this.filters.service;
      const services = objectToArrayOfObjects(this.filtersList.services);
      return setSelectedOptions(services, service);
    },

    nationalitySelectOptions() {
      const sourceOptions =
        this.availableFilters?.nationality ||
        this.filtersList?.nationality_new ||
        {};

      return Object.keys(sourceOptions).map((key) => {
        return {
          value: key,
          title: sourceOptions[key].title,
        };
      });
    },

    ageSelectOptions() {
      return objectToArrayOfObjects(this.filtersList.age);
    },

    hairsSelectOptions() {
      return (
        this.filtersList?.hair_color?.map((hc) => {
          return {
            title: hc.title,
            value: `${hc.id}`,
          };
        }) || []
      );
    },

    budgetSelectOptions() {
      return objectToArrayOfObjects(this.filtersList.budget);
    },

    areaSelectOptions() {
      let districts = [];
      if (!this.filtersList || !this.filtersList.districts) {
        return [];
      }

      for (let i = 0; i < this.filtersList.districts.length; i++) {
        let district = this.filtersList.districts[i];
        let cityId = this.isMain ? 1 : this.currentCity.id;
        if (district.city_id == cityId) {
          districts.push({
            title: district.title,
            value: "" + district.id,
          });
        }
      }

      return districts;
    },

    selectList() {
      return [
        {
          placeholder: this.$t("service"),
          options: this.servicesSelectOptions,
          filterName: "service",
        },
        {
          placeholder: this.$t("nationality"),
          options: this.nationalitySelectOptions,
          filterName: "nationality",
        },
        {
          placeholder: this.$t("age"),
          options: this.ageSelectOptions,
          filterName: "age",
        },
        {
          placeholder: this.$t("hair-color"),
          options: this.hairsSelectOptions,
          filterName: "hair_color",
        },
        {
          placeholder: this.$t("budget"),
          options: this.budgetSelectOptions,
          filterName: "budget",
        },
        {
          placeholder: this.$t("area"),
          options: this.areaSelectOptions,
          filterName: "district",
        },
      ];
    },
  },

  watch: {
    filters: {
      handler(val) {
        Object.keys(val).forEach((key) => {
          if (this.form[key] !== undefined) {
            const value = val[key];
            if (this.selectList.some((select) => select.filterName === key)) {
              this.form[key] = value ? `${value}`.split(",") : [];
            } else {
              this.form[key] = value;
            }
          }
        });
        this.selectList.forEach((select) => {
          if (val[select.filterName] === undefined) {
            this.form[select.filterName] = [];
          }
        });
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    onFilterChange(filter, filterName) {
      const value = filter.value ? filter.value : filter;
      this.$store.commit(
        "profiles/setFiltersForce",
        {
          [filterName]: value,
        },
        true
      );

      this.$store.commit("profiles/addFilterChangedByUser", filterName);

      if (!this.showApplyBtn) {
        this.fetchProfilesDebounced();
      }
    },

    fetchProfilesDebounced: debounce(function () {
      this.$store.dispatch("profiles/retrieveProfilesAllShort");
    }, 500),

    apply() {
      this.$store.commit("profiles/setIsForwardedFromMain", true);
      this.$router.push({
        path: "/dubai/female",
      });
    },

    async resetFilters() {
      await this.$store.dispatch("profiles/resetFilters", true);
      this.$root.$emit("scroll-to-top");
      this.$store.dispatch("profiles/retrieveProfilesAllShort");
      this.form = { ...defaultForm };
    },
  },
};
