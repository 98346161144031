import { render, staticRenderFns } from "./profile-card.vue?vue&type=template&id=47e540e4"
import script from "./profile-card.vue?vue&type=script&lang=js"
export * from "./profile-card.vue?vue&type=script&lang=js"
import style0 from "./profile-card.vue?vue&type=style&index=0&id=47e540e4&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VSkeletonLoader: require('/app/node_modules/vuetify/lib/components/VSkeletonLoader/VSkeletonLoader.js').default})


/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VSkeletonLoader})
