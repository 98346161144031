import { render, staticRenderFns } from "./hd-user-panel.vue?vue&type=template&id=0bc76e76"
import script from "./hd-user-panel.vue?vue&type=script&lang=js"
export * from "./hd-user-panel.vue?vue&type=script&lang=js"
import style0 from "./hd-user-panel.vue?vue&type=style&index=0&id=0bc76e76&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VBtn: require('/app/node_modules/vuetify/lib/components/VBtn/VBtn.js').default,VListItem: require('/app/node_modules/vuetify/lib/components/VList/VListItem.js').default,IconLogoutMenu: require('/app/components/icons/IconLogoutMenu.vue').default,VList: require('/app/node_modules/vuetify/lib/components/VList/VList.js').default,VMenu: require('/app/node_modules/vuetify/lib/components/VMenu/VMenu.js').default,VBadge: require('/app/node_modules/vuetify/lib/components/VBadge/VBadge.js').default})


/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
installComponents(component, {VBadge,VBtn,VList,VListItem,VListItemTitle,VMenu})
