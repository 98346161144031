
import { mapGetters } from "vuex";
import { camelCaseToDashes } from "~/common/helpers";
import { mdiCloseCircle } from "@mdi/js";

export default {
  props: {
    urlPrefix: {
      type: String,
      default: "",
    },
  },

  emit: ["category-changed"],

  data() {
    return {
      mdiCloseCircle,
    };
  },

  async fetch() {
    await this.$store.dispatch("menus/retrieveMainFilters");
  },

  computed: {
    ...mapGetters({
      statusNav: "menus/getStatusNav",
      currentLocationUrl: "global/getCurrentLocationUrl",
      decodedRouteParams: "profiles/getDecodedRouteParams",
      filters: "profiles/getFilters",
    }),
    routeName() {
      return this.$route.name;
    },
    gender() {
      if (!this.isCatalog) {
        return "female";
      }
      const genders = ["male", "female", "transgender"];

      return genders.find((sub) => {
        const regex = new RegExp(`/${sub}(?=/|$)`);
        return regex.test(this.$route.path);
      });
    },
    isCatalog() {
      return this.routeName.includes("catalog");
    },
    isMain() {
      return this.routeName == "index";
    },
    isLink() {
      return !this.isCatalog || this.gender;
    },
    events() {
      return {
        click: this.fetchProfilesByStatus,
      };
    },
  },
  methods: {
    categoryToUrl(category) {
      let path = "";
      if (this.isMain) {
        path = "dubai/female";
      } else {
        path += this.currentLocationUrl;
        path += this.gender ? "/" + this.gender : "";
      }
      path += "/" + camelCaseToDashes(category);
      return path;
    },

    cancelStatus() {
      const pathWithoutStatus = this.$route.path
        .split("/")
        .slice(0, -1)
        .join("/");
      this.$router.push({ path: pathWithoutStatus });
    },

    fetchProfilesByStatus(filterId, value) {
      const newFilters = {};
      this.statusNav.forEach((item) => {
        newFilters[item.id] = item.id === filterId ? value : "";
      });

      this.$store.commit("profiles/setFilters", newFilters);
      if (!this.isLink) {
        this.$root.$emit("scroll-to-top");
      }
      this.$store.dispatch("profiles/retrieveProfilesAllShort");

      this.$emit("category-changed", filterId);
    },

    emitCatChanged(id) {
      this.$emit("category-changed", id);
    },

    onCrossClick(id) {
      if (this.isLink) {
        this.cancelStatus();
      } else {
        this.fetchProfilesByStatus(id, "");
      }

      this.$emit("category-changed", id);
    },

    url(item) {
      return this.isLink
        ? `${this.urlPrefix}/${this.categoryToUrl(item.url)}`
        : null;
    },

    isSelectedBtn(itemId) {
      return (
        Object.keys(this.filters || {}).includes(itemId) &&
        (this.filters || {})[itemId] != "0"
      );
    },

    clases(item) {
      return [
        "hd-category-nav__btn",
        { "nuxt-link-exact-active": this.isSelectedBtn(item.id) },
      ];
    },
  },
};
