
import { mapGetters } from "vuex";

const filtersTodisplay = {
  withVideo: {
    title: "Has video",
    value: false,
  },
  withReviews: {
    title: "Has reviews",
    value: false,
  },
  has_natural_photo: {
    title: "Has natural photo",
    value: false,
  },
  service: {
    title: "Service",
    value: true,
    convertKey: "services",
  },
  nationality: {
    title: "Nationality",
    value: true,
    convertKey: "nationality",
  },
  age: {
    title: "Age",
    value: true,
    format(val) {
      return val.split(",");
    },
  },
  hair_color: {
    title: "Hair color",
    value: true,
    convertKey: "hair_color",
  },
  budget: {
    title: "Budget",
    value: true,
    format(val) {
      return val.split(",");
    },
  },
  district: {
    title: "Area",
    value: true,
    convertKey: "districts",
  },
  searchQuery: {
    title: "Search query",
    value: true,
  },
  qualityMark: {
    title: "Quality mark",
    value: false,
  },
  service_for: {
    title: "Service for",
    value: true,
    convertKey: "service_for",
  },
  pornstar: {
    title: "Pornstar",
    value: false,
  },
  verified: {
    title: "Verified",
    value: false,
  },
  availability: {
    title: "Availability",
    value: true,
    format(val) {
      return val.replace("in_tour", "On tour").replaceAll("_", " ").split(",");
    },
  },
  gender: {
    title: "Gender",
    value: true,
    convertKey: "gender",
    format(val) {
      return val.split(",");
    },
  },
  orientation: {
    title: "Orientation",
    value: true,
    convertKey: "orientation",
  },
  languages: {
    title: "Languages",
    value: true,
    convertKey: "languages",
  },
  messangers: {
    title: "Messangers",
    value: true,
    format(val) {
      return val.split(",");
    },
  },
  socialMedia: {
    title: "Social media",
    value: true,
    format(val) {
      return val.split(",");
    },
  },
  public_hair: {
    title: "Public hair",
    value: false,
  },
  tattoos: {
    title: "Tattoos",
    value: false,
  },
  drinking: {
    title: "Drinking",
    value: false,
  },
  big_ass: {
    title: "Big ass",
    value: false,
  },
  piercing: {
    title: "Piercing",
    value: false,
  },
  smoking: {
    title: "Smoking",
    value: false,
  },
  ethnicity: {
    title: "Ethnicity",
    value: true,
    convertKey: "ethnicity",
  },
  breast: {
    title: "Breast",
    value: true,
    format(val) {
      return val.split(",");
    },
  },
  hair_length: {
    title: "Hair length",
    value: true,
  },
  height: {
    title: "Height",
    value: true,
  },
  weight: {
    title: "Weight",
    value: true,
  },
  bust_size: {
    title: "Bust size",
    value: true,
  },
};

export default {
  props: {
    oneLine: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters({
      filters: "profiles/getFilters",
      filtersList: "profiles/getFiltersList",
      filtersChangedByUser: "profiles/getFiltersChangedByUser",

      profilesAllShort: "profiles/getProfilesAllShort",
    }),

    resultsCounts() {
      return this.profilesAllShort?.length || 0;
    },

    tags() {
      return Object.keys(this.filters).reduce((result, key) => {
        const filter = filtersTodisplay[key];

        if (!filter) return result;
        if (!this.filtersChangedByUser.has(key)) {
          return result;
        }

        const title = filter?.title || key || "Filter";

        if (this.filters[key] === "0") {
          return result;
        }

        //For cases {key: value} - example: {budget:"0 - 5000"}
        let value = filter?.value ? this.filters[key] : null;

        const convertKey = filter?.convertKey;

        if (convertKey) {
          let splitedValue = value ? value.split(",") : [];
          const sourceFilter = this.filtersList[convertKey];

          if (!sourceFilter) return result;

          if (key === "gender") {
            value = filter.format(value);
          } else if (Array.isArray(sourceFilter)) {
            splitedValue = new Set(splitedValue);
            const filtered = sourceFilter
              .filter(
                (f) =>
                  splitedValue.has(+f.id) ||
                  splitedValue.has(+f.alias) ||
                  splitedValue.has(`${f.id}`) ||
                  splitedValue.has(`${f.alias}`)
              )
              .map((f) => f.title);

            if (filtered.length === 0) return result;

            value = filtered;
          } else if (typeof sourceFilter === "object") {
            if (splitedValue.length === 0) {
              return result;
            }

            value = splitedValue.map(
              (v) =>
                sourceFilter[v]?.title || sourceFilter[v] || sourceFilter[+v]
            );
          }
        } else if (filter.format) {
          value = filter.format(value);
        }

        result.push({
          key,
          title,
          value,
          requestValue: this.filters[key],
        });

        return result;
      }, []);
    },
  },

  methods: {
    removeFilter(key) {
      const filrerToremove = {};
      filrerToremove[key] = null;
      this.$store.commit("profiles/setFilters", filrerToremove);
      this.$root.$emit("scroll-to-top");
      this.$store.dispatch("profiles/retrieveProfilesAllShort");
    },

    removeOneValue(tag, index) {
      const value = tag.requestValue.split(",");
      value.splice(index, 1);
      const updatedFilter = {};
      updatedFilter[tag.key] = value.join(",");
      this.$store.commit("profiles/setFilters", updatedFilter);
      this.$root.$emit("scroll-to-top");
      this.$store.dispatch("profiles/retrieveProfilesAllShort");
    },
  },
};
