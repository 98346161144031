
import CSwitch from "~/components/v2/ui/c-switch.vue";
import IconSearch from "~/components/icons/IconSearch.vue";
import IconGrig from "~/components/icons/IconGrig.vue";
import IconList from "~/components/icons/IconList.vue";
import { mdiMenuDown } from "@mdi/js";
import { mapGetters, mapMutations } from "vuex";
import { debounce } from "~/common/helpers";

export default {
  components: {
    CSwitch,
    IconSearch,
    IconGrig,
    IconList,
  },

  emits: ["switchFiltersPanel"],

  data() {
    return {
      isAdvancedSearchOpen: false,
      isFiltersPanelOpen: false,
      mdiMenuDown,

      switchesForm: {
        withVideo: "0",
        withReviews: "0",
        has_natural_photo: "0",
      },
    };
  },

  computed: {
    ...mapGetters({
      discreetMode: "getDiscreetStatus",
      catalogMode: "catalog/getCatalogMode",
      filters: "profiles/getFilters",
    }),
  },

  watch: {
    filters: {
      handler(val) {
        this.switchesForm = {
          withVideo: "0",
          withReviews: "0",
          has_natural_photo: "0",
        };
        Object.keys(val).forEach((key) => {
          if (this.switchesForm[key] !== undefined) {
            this.switchesForm[key] = val[key];
          }
        });
      },
      deep: true,
      immediate: true,
    },
  },

  mounted() {
    this.$root.$on("advanced-search:closed", () => {
      this.isAdvancedSearchOpen = false;
    });
  },

  methods: {
    ...mapMutations({
      setCatalogMode: "catalog/setCatalogMode",
    }),

    viewSwitcher(mode) {
      this.setCatalogMode(mode);
    },

    switchFiltersPanel() {
      this.isFiltersPanelOpen = !this.isFiltersPanelOpen;
      this.$emit("switchFiltersPanel", this.isFiltersPanelOpen);
    },

    openAdvancedSearch() {
      this.$root.$emit("openAdvancedSearch");
    },

    changeAdvancedSearch(value) {
      if (value) {
        this.openAdvancedSearch();
      }
    },

    setDiscreetMode() {
      this.$store.commit("setDiscreet");
    },

    onFilterChange(filter, filterName) {
      const value = filter.value ? filter.value : filter;
      this.$store.commit("profiles/setFiltersForce", {
        [filterName]: value,
      });
      this.$store.commit("profiles/addFilterChangedByUser", filterName);
      if (!this.isMainPage) {
        this.$root.$emit("scroll-to-top");
        this.fetchProfilesDebounced();
      }
    },

    fetchProfilesDebounced: debounce(function () {
      this.$store.dispatch("profiles/retrieveProfilesAllShort");
    }, 500),
  },
};
