import { render, staticRenderFns } from "./profile-menu-v2.vue?vue&type=template&id=21535f1f"
import script from "./profile-menu-v2.vue?vue&type=script&lang=js"
export * from "./profile-menu-v2.vue?vue&type=script&lang=js"
import style0 from "./profile-menu-v2.vue?vue&type=style&index=0&id=21535f1f&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconDotsVertical: require('/app/components/icons/IconDotsVertical.vue').default,VBtn: require('/app/node_modules/vuetify/lib/components/VBtn/VBtn.js').default,IconMessage: require('/app/components/icons/IconMessage.vue').default,VListItemIcon: require('/app/node_modules/vuetify/lib/components/VList/VListItemIcon.js').default,VListItem: require('/app/node_modules/vuetify/lib/components/VList/VListItem.js').default,IconShare: require('/app/components/icons/IconShare.vue').default,IconReport: require('/app/components/icons/IconReport.vue').default,VDivider: require('/app/node_modules/vuetify/lib/components/VDivider/VDivider.js').default,VIcon: require('/app/node_modules/vuetify/lib/components/VIcon/VIcon.js').default,CBtn: require('/app/components/ui/button/c-btn.vue').default,VList: require('/app/node_modules/vuetify/lib/components/VList/VList.js').default,VMenu: require('/app/node_modules/vuetify/lib/components/VMenu/VMenu.js').default})


/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
installComponents(component, {VBtn,VDivider,VIcon,VList,VListItem,VListItemContent,VListItemIcon,VListItemTitle,VMenu})
