import { render, staticRenderFns } from "./reportProfileModal.vue?vue&type=template&id=520cbd5e"
import script from "./reportProfileModal.vue?vue&type=script&lang=js"
export * from "./reportProfileModal.vue?vue&type=script&lang=js"
import style0 from "./reportProfileModal.vue?vue&type=style&index=0&id=520cbd5e&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VIcon: require('/app/node_modules/vuetify/lib/components/VIcon/VIcon.js').default,VTextField: require('/app/node_modules/vuetify/lib/components/VTextField/VTextField.js').default,VRadio: require('/app/node_modules/vuetify/lib/components/VRadioGroup/VRadio.js').default,VRadioGroup: require('/app/node_modules/vuetify/lib/components/VRadioGroup/VRadioGroup.js').default,VForm: require('/app/node_modules/vuetify/lib/components/VForm/VForm.js').default,CBtn: require('/app/components/ui/button/c-btn.vue').default,CModal: require('/app/components/ui/modal/c-modal.vue').default})


/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VForm,VIcon,VRadio,VRadioGroup,VTextField})
